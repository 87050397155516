<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/index_a.png" alt="" width="28">
        <h2>实验课题</h2>
        <span class="num">共计：{{ page.total }}条记录</span>
      </div>
      <el-button size="small">历史记录</el-button>
    </div>
    <div class="contentBody">
      <div class="flex tableHead">
        <el-button size="small" @click="$router.push({name:'newExper'})">新增实验</el-button>
        <el-input clearable v-model="search" placeholder="请输入实验编号" suffix-icon="el-icon-search"
                  @change="skipPage(1)"></el-input>
      </div>
      <el-table
          border
          :data="tableData"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 340px)">
        <el-table-column
            prop="number"
            label="实验编号"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="name"
            label="实验名称"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="dirNickname"
            label="负责人" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="strain"
            label="动物品系" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="address"
            label="实验动物数量">
          <el-table-column
              prop="femaleNum"
              label="雌性"
              min-width="90">
          </el-table-column>
          <el-table-column
              prop="maleNum"
              label="雄性"
              min-width="90">
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="计划开始时间" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.startTime">{{ scope.row.startTime | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="计划实验周期" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.period">{{ scope.row.period | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="实验状态" width="150">
          <template slot-scope="scope">
            <el-button size="small" class="suspended" v-if="scope.row.status==0">草稿</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.status==2">实验审批中</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.status==4">实验审批不通过</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.status==14">兽医审核不通过</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.status==46">接种审核不通过</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.status==42">供试品配置复核不通过</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.status==57">终点复核不通过</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.status==60">分组复核不通过</el-button>
            <el-button size="small" v-else class="experimentCo">
              <template v-if="scope.row.status==5">去请购</template>
              <template v-if="scope.row.status==6">动物请购中</template>
              <template v-if="scope.row.status==8">去接收</template>
              <template v-if="scope.row.status==9">动物接收中</template>
              <template v-if="scope.row.status==10">去检疫</template>
              <template v-if="scope.row.status==11">动物检疫中</template>
              <template v-if="scope.row.status==12">兽医复核中</template>
              <template v-if="scope.row.status==15">去申领</template>
              <template v-if="scope.row.status==16">申领中</template>
              <template v-if="scope.row.status==17">去分笼</template>
              <template v-if="scope.row.status==18">动物分笼中</template>
              <template v-if="scope.row.status==19">去建模</template>
              <template v-if="scope.row.status==20">建模悬浮液配置中</template>
              <template v-if="scope.row.status==21">建模悬浮液复核中</template>
              <template v-if="scope.row.status==22">去接种</template>
              <template v-if="scope.row.status==43">接种中</template>
              <template v-if="scope.row.status==44">接种复核中</template>
              <template v-if="scope.row.status==23">去药前</template>
              <template v-if="scope.row.status==24">药前进行中</template>
              <template v-if="scope.row.status==25">去分组</template>
              <template v-if="scope.row.status==26">分组中</template>
              <template v-if="scope.row.status==27">实验分组复核中</template>
              <template v-if="scope.row.status==28">去供试品配制</template>
              <template v-if="scope.row.status==29">供试品配制中</template>
              <template v-if="scope.row.status==41">供试品配制复核中</template>
              <template v-if="scope.row.status==50">去给药</template>
              <template v-if="scope.row.status==51">药后记录</template>
              <template v-if="scope.row.status==52">实验终点</template>
              <template v-if="scope.row.status==55">实验终点草稿中</template>
              <template v-if="scope.row.status==56">终点复核中</template>
              <template v-if="scope.row.status==58">去上传实验报告</template>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.status==0" @click="toEdit(1,scope.row.id)">编辑</el-button>
            <el-button type="text" v-else @click="toDetail(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "experiment",
  data() {
    return {
      search: '',
      tableData: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getData();
    },
    getData() {
      let that = this
      let params = {
        pageSize: that.page.size,
        pageNum: that.page.current,
        key: that.search,
        type: 0
      }
      that.$get("/subject/byPage", params).then((res) => {
        if (res.status == 200) {
          this.tableData = res.data.records;
          this.page.current = res.data.current;
          this.page.total = res.data.total;
          this.page.size = res.data.size;
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    toDetail(id) {
      this.$router.push({name: 'detail', query: {id: id}})
    },
    toEdit(state, id) {
      this.$router.push({
        name: 'newExper',
        query: {state: state, id: id}
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/exper/experiment";
</style>
